import { useRef, useState } from "react"
import classNames from "classnames"
import useOnClickOutside from "@/lib/hooks/useOnClickOutside"
import useBreakpoint from "@/lib/hooks/useBreakpoint"
import { Tooltip as TooltipIcon } from "@/common/icons/member/tooltip"
import Tooltip from "@/common/tooltip/tooltip-label"
import styles from "@/common/tooltip/tooltip-label.module.css"

function Label({
  name = false,
  children,
  required,
  description = false,
  tooltip = false,
  className = {}
}) {
  const { breakpoint } = useBreakpoint()
  const tooltipPosition = ["xsm"].includes(breakpoint) ? "under" : "right"
  const [tooltipOpen, setTooltipOpen] = useState(false)
  const tooltipRef = useRef(null)

  useOnClickOutside(
    tooltipRef,
    () => {
      setTooltipOpen(!tooltipOpen)
    },
    tooltipOpen
  )

  return (
    <>
      <label
        htmlFor={name || null}
        className={classNames(
          "flex relative font-medium text-left",
          {
            "cursor-pointer": name
          },
          className
        )}>
        {children}

        {required && <span className="ml-1 text-red-100">*</span>}

        {tooltip && (
          <div ref={tooltipRef}>
            <div
              className={classNames(
                "w-6 h-6 ml-2 relative cursor-pointer",
                tooltipPosition,
                styles.holder,
                {
                  active: tooltipOpen
                }
              )}>
              <div
                onClick={(e) => {
                  if (
                    e.target.parentElement.nodeName !== "DIV" ||
                    e.target.parentElement.nodeName !== "A"
                  ) {
                    e.preventDefault()
                    setTooltipOpen(!tooltipOpen)
                  }
                }}>
                <TooltipIcon />
              </div>
              {tooltipPosition === "right" && tooltipOpen && (
                <Tooltip
                  html={tooltip}
                  position={tooltipPosition}
                  close={() => {
                    setTooltipOpen(false)
                  }}
                />
              )}
            </div>
            {tooltipPosition === "under" && tooltipOpen && (
              <Tooltip
                className="feed-tooltip"
                html={tooltip}
                position={tooltipPosition}
                close={() => {
                  setTooltipOpen(!tooltipOpen)
                }}
              />
            )}
          </div>
        )}
      </label>
      <div className="mb-2 text-sm">{description}</div>
    </>
  )
}

export default Label
